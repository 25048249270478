export function simplifiedProxyModel (
  props: any,
  prop: any,
  action = 'update'
) {
  const VM = getCurrentInstance()

  return computed({
    get: () => props[prop],
    set: value => VM?.emit(`${action}:${prop}`, value)
  })
}

export function proxyModel<
  Props extends object,
  Prop extends Extract<keyof Props, string>,
  Inner = Props[Prop],
> (
  props: Props,
  prop: Prop,
  defaultValue?: Props[Prop],
  // eslint-disable-next-line no-unused-vars
  transformIn: (value?: Props[Prop]) => Inner = (v: any) => v,
  // eslint-disable-next-line no-unused-vars
  transformOut: (value: Inner) => Props[Prop] = (v: any) => v,
  action = 'update'
) {
  const VM = getCurrentInstance()

  const isDefined = computed(() => {
    return !!(
      typeof props[prop] !== 'undefined' &&
      typeof props[prop] !== null &&
      (VM?.props?.hasOwnProperty(prop) || VM?.props?.hasOwnProperty(strToKebabCase(prop)))
    )
  })

  const internal = ref(transformIn(props[prop])) as Ref<Inner>

  return computed({
    get: () => {
      if (isDefined.value) {
        return transformIn(props[prop])
      }

      return internal.value
    },
    set: value => {
      const checking = isDefined.value ? transformIn(props[prop]) : internal.value

      if (checking === value) {
        return
      }

      internal.value = value
      VM?.emit(`${action}:${prop}`, transformOut(value))
    }
  })
}
